<script setup lang="ts">
import { storeToRefs } from 'pinia'

import NavLink from '~/components/nav/NavLink.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'

import { useUserStore } from '~/stores/user'

const { grooviz: GROOVIZ } = storeToRefs(useUserStore())

const segmentTrack = useSegmentTrack()

function handleTrackReferralAccessSegment() {
  segmentTrack('Referral Curator - Page Accessed', {
    accessed_from: 'top navbar',
  })
}
</script>

<template>
  <div>
    <NavLink
      class="sharedNavigationText"
      to="/influencer/list/all/"
      data-test-id="curatorTopNavigationAllCurators"
      >{{ $t('layout.navigation12') }}</NavLink
    >
    <NavLink
      class="sharedNavigationText"
      to="/influencer/dashboard/"
      data-test-id="curatorTopNavigationDashboard"
      >{{ $t('layout.navigation03') }}</NavLink
    >
    <NavLink
      class="sharedNavigationText"
      to="/influencer/widget/"
      data-test-id="curatorTopNavigationReceiveMore"
      @click="handleTrackReferralAccessSegment"
      >{{ $t('components.nav.loggedInCurator.widget') }}</NavLink
    >
    <NavLink
      class="sharedNavigationText"
      to="/influencer/cashout/"
      data-test-id="curatorTopNavigationWallet"
    >
      <span class="tw-hidden lg:tw-inline-block">{{
        $t('layout.navigation08', GROOVIZ)
      }}</span>
      <span class="tw-inline-block lg:tw-hidden">{{
        $t('layout.countNoNumber')
      }}</span>
    </NavLink>
  </div>
</template>
