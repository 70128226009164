<script setup lang="ts">
type Props = {
  to?: string
}
type Emits = {
  click: []
}

const props = withDefaults(defineProps<Props>(), { to: '' })
const emit = defineEmits<Emits>()

const component = computed(() =>
  props.to ? resolveComponent('NuxtLinkLocale') : 'button',
)
const componentAttrs = computed(() =>
  props.to ? { to: props.to } : { type: 'button' },
)
</script>

<script lang="ts">
/**
 * Create navigation links/buttons within the navbar and navbar menus.
 * If no "to" prop is defined, then the component will render a button element.
 */
export default {}
</script>

<template>
  <component
    :is="component"
    v-bind="componentAttrs"
    class="tw-text-left tw-text-sm tw-font-medium tw-text-black"
    @click="emit('click')"
  >
    <slot />
  </component>
</template>
