<script setup lang="ts">
import { storeToRefs } from 'pinia'

import NavLoggedIn from '~/components/nav/LoggedIn.vue'
import NavLoggedOut from '~/components/nav/loggedOut.vue'
import NavMobile from '~/components/nav/mobile.vue'
import LogoIcon from '~/components/ui/LogoIcon/LogoIcon.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useUserStore } from '~/stores/user'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { Breakpoints } from '~/enums/breakpoints'

const { getMediaUrl } = useGetMediaUrl()
const localePath = useLocalePath()
const { availableLocales } = useI18n()
const route = useRoute()

const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore())
const { step: BAND_SIGNUP_STEP } = storeToRefs(useBandSignupStore())
const { IS_LOGGED_IN, IS_INCOMPLETE_USER } = storeToRefs(useUserStore())

const { SET_STEP: BAND_SIGNUP_SET_STEP } = useBandSignupStore()

const isBandSignup = computed<boolean>(
  () =>
    route.path.includes('/band/signup/') && !route.path.includes('discovery'),
)

const isSignup = computed<boolean>(() => route.path.includes('/signup/'))

const isIndex = computed<boolean>(() => {
  const availableIndexRoutes = availableLocales.map((lang) => {
    return `/${lang}/`
  })

  return availableIndexRoutes.includes(route.path) && !IS_LOGGED_IN.value
})

function handleChevronLeftClick() {
  if (route.path.includes('/discovery/')) {
    return navigateTo(localePath('/band/signup/'))
  } else {
    BAND_SIGNUP_SET_STEP(BAND_SIGNUP_STEP.value - 1)
    trackEvent(
      {
        category: 'Band Signup',
        action: 'Previous Step',
        from: BAND_SIGNUP_STEP.value + 1,
        to: BAND_SIGNUP_STEP.value,
      },
      route,
    )
  }
}
</script>

<template>
  <div
    id="navIndexWrapper"
    class="navIndexWrapper"
    :class="{ isIndex: isIndex && SCREEN_WIDTH < Breakpoints.md }"
  >
    <div class="mainParentContainer">
      <i
        v-if="isBandSignup && SCREEN_WIDTH < Breakpoints.md"
        class="fas fa-chevron-left tw-cursor-pointer tw-text-base tw-text-discrete2"
        :class="{ 'tw-opacity-0': BAND_SIGNUP_STEP === 0 }"
        @click="handleChevronLeftClick"
      />
      <NuxtLinkLocale class="navLogo tw-shrink-0" to="/">
        <img
          v-if="SCREEN_WIDTH < Breakpoints.md || SCREEN_WIDTH >= Breakpoints.lg"
          :src="getMediaUrl('GrooverLogoWithText-Primary.svg')"
          width="136px"
          alt="Groover logo"
        />
        <LogoIcon v-else size="xl" aria-label="Groover logo" />
      </NuxtLinkLocale>
      <div
        v-if="SCREEN_WIDTH >= Breakpoints.md && !isSignup"
        class="flexIt"
        :class="{ 'tw-w-full': !IS_LOGGED_IN }"
      >
        <transition name="fade" mode="out-in">
          <NavLoggedIn
            v-if="IS_LOGGED_IN && !IS_INCOMPLETE_USER"
            key="loggedIn"
          />
          <NavLoggedOut v-else key="loggedOut" />
        </transition>
      </div>
      <NavMobile v-else-if="!isSignup" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.navIndexWrapper.navNoShadow {
  box-shadow: unset;
}
.navIndexWrapper {
  width: 100%;
  position: relative;
  background-color: var(--color-white);
  box-shadow: var(--shadow-regular);
  z-index: 10001;
}

.navIndexWrapper.isIndex {
  background-color: unset;
  box-shadow: unset;
}

.mainParentContainer {
  @apply tw-z-50 tw-flex tw-items-center tw-justify-between;

  padding: 18px 0px;
  height: 60px;

  @screen md {
    @apply tw-py-4;

    min-height: 80px;
    height: unset;
  }
}

.navLogo {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.navLogo > img {
  @apply tw-h-8 tw-object-contain tw-object-center;
}

.navLogo > span {
  @apply tw-ml-1 tw-font-sans tw-text-xl tw-font-bold tw-text-orange-500;
}

.isIndex .flexIt {
  width: 100%;
}

.flexIt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexIt > * {
  margin-right: 32px;
}

.flexIt > :deep(:last-child) {
  margin-right: 0px;
  margin-left: auto;
}

.navIndexWrapper * {
  font-size: var(--font-size);
}

@media screen and (min-width: 1024px) {
  .flexIt > * {
    margin-right: 48px;
  }
}
</style>
