<script setup lang="ts">
import { UITag } from '@groover-dev/groover-ui'
import { storeToRefs } from 'pinia'

import NavLink from '~/components/nav/NavLink.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useSegmentTrackCampaign } from '~/composables/useSegmentTrackCampaign'

import { useUserStore } from '~/stores/user'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

const segmentTrack = useSegmentTrack()
const { trackCampaignInitiated } = useSegmentTrackCampaign()
const route = useRoute()

const {
  grooviz: GROOVIZ,
  HAS_PAID: USER_HAS_PAID,
  first_payment_date: FIRST_PAYMENT_DATE,
} = storeToRefs(useUserStore())

function trackArtistReferral() {
  segmentTrack('Referral Artist - Page Accessed', {
    accessed_from: 'top navbar',
  })
}

function emitDraftStart() {
  trackCampaignInitiated('header')
  trackEvent(
    {
      category: 'Campaign',
      action: 'Start',
      method: 'Navigation',
      balance: GROOVIZ.value,
      firstPaymentDate: FIRST_PAYMENT_DATE.value,
    },
    route,
  )
}
</script>

<template>
  <div>
    <NavLink
      to="/draft/"
      class="sharedNavigationText"
      data-test-id="bandTopNavigationStartCampaignCTA"
      @click="emitDraftStart"
    >
      {{ $t('layout.navigation.button.startNewDraft.campaign') }}
    </NavLink>
    <NavLink
      to="/band/dashboard/"
      data-test-id="bandTopNavigationCampaignsCTA"
      class="sharedNavigationText"
    >
      {{ $t('layout.navigation04') }}
    </NavLink>
    <NavLink
      to="/lp/our-services/"
      data-test-id="bandTopNavigationServicesCTA"
      class="sharedNavigationText tw-flex tw-h-[41px] tw-items-center tw-gap-x-xs"
    >
      {{ $t('layout.navbar.artist.servicesLink') }}
      <UITag
        :text="$t('common.new')"
        color="primary"
        size="sm"
        class="tw-capitalize"
      />
    </NavLink>
    <NavLink
      v-if="USER_HAS_PAID"
      to="/band/referral/"
      data-test-id="bandTopNavigationReferralCTA"
      @click="trackArtistReferral"
    >
      <i class="fas fa-gift icon lg:!tw-hidden" />
      <span class="sharedNavigationText !tw-hidden tw-items-center lg:!tw-flex"
        >{{ $t('layout.navbar.artist.referralLink')
        }}<i
          class="icon-grooviz tw-ml-3xs !tw-text-base"
          aria-label="Grooviz"
        />
      </span>
    </NavLink>
  </div>
</template>

<style lang="scss" scoped>
.dashMenu {
  top: calc(100% + 20px);
}

.icon {
  @apply tw-mx-3 tw-h-4;

  @screen lg {
    @apply tw-mx-4;
  }

  @screen xl {
    @apply tw-mx-6;
  }
}

.fas.fa-gift {
  @apply tw-cursor-pointer tw-text-lg tw-text-icon-primary;

  @keyframes wiggleWaggle {
    from {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: 100ms ease-in-out 4 1500ms reverse both running wiggleWaggle;
  }
}
</style>
