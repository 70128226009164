<script setup lang="ts">
import VText from '~/components/ui/VText.vue'

import { useArtistProfileForce } from '~/composables/useArtistProfileForce'

import type { Band } from '~/types/band'

type Props = {
  band: Band
}

type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { bandForce } = useArtistProfileForce(props.band)
</script>

<template>
  <div class="tw-grid tw-grid-cols-1 tw-gap-sm">
    <VText v-once cfg="sans/16/medium">{{ band.name }}</VText>
    <div class="infos">
      <VText cfg="sans/14/regular" color="gray-500">
        {{ $t('band.RPMenu.completion', { force: bandForce }) }} ·
      </VText>
      <NuxtLinkLocale
        :to="`/band/profile/${band.slug}/`"
        @click="emit('close')"
      >
        <VText
          cfg="sans/14/medium"
          color="orange-500"
          data-test-id="profileDropdownViewProfileCTA"
        >
          {{ $t('band.RPMenu.profile') }}
        </VText>
      </NuxtLinkLocale>
    </div>
  </div>
</template>
