<script setup lang="ts" generic="K extends 'band' | 'influencer'">
import ArtistProfileInfos from '~/components/nav/loggedIn/contextMenu/ProfileInfosArtist.vue'
import CuratorProfileInfos from '~/components/nav/loggedIn/contextMenu/ProfileInfosCurator.vue'

import type { Band } from '~/types/band'
import type { Influencer } from '~/types/influencer'

type Props = {
  kind: K
  entity: K extends 'influencer' ? Influencer : Band
}
type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const configuration = computed(() => {
  return {
    component:
      props.kind === 'band'
        ? markRaw(ArtistProfileInfos)
        : markRaw(CuratorProfileInfos),
    props:
      props.kind === 'band'
        ? {
            band: props.entity as Band,
          }
        : props,
  }
})
</script>

<template>
  <component
    :is="configuration.component"
    v-bind="configuration.props"
    @close="$emit('close')"
  />
</template>
