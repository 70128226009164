<script setup lang="ts">
import { storeToRefs } from 'pinia'

import NavLink from '~/components/nav/NavLink.vue'
import NavLinkCharts from '~/components/nav/NavLinkCharts.vue'

import { useGrowthBook } from '~/composables/useGrowthBook'

import { useUserStore } from '~/stores/user'

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()

const { grooviz: GROOVIZ } = storeToRefs(useUserStore())
const { isFeatureOnForGroup } = useGrowthBook()
</script>

<template>
  <div class="tw-grid tw-grid-cols-1">
    <!-- Start AB Test 'charts-rollout' -->
    <NavLinkCharts
      v-if="isFeatureOnForGroup('charts-rollout', 'enabled')"
      class="hoverText"
    />
    <!-- End AB Test 'charts-rollout' -->
    <NavLink
      to="/influencer/list/all/"
      class="hoverText"
      @click="emit('close')"
      >{{ $t('layout.navigation12') }}</NavLink
    >
    <NavLink
      class="hoverText tw-flex tw-items-center tw-justify-start tw-gap-sm"
      to="/influencer/cashout/"
      @click="emit('close')"
    >
      <span> {{ $t('layout.navigation08', GROOVIZ) }} </span
      ><i
        class="icon-grooviz !tw-text-base tw-text-icon-discrete2"
        aria-label="Grooviz"
      />
    </NavLink>
    <NavLink
      class="hoverText"
      to="/influencer/dashboard/"
      @click="emit('close')"
      >{{ $t('layout.navigation03') }}</NavLink
    >
  </div>
</template>
