<script setup lang="ts">
import { useDraftStore } from '~/stores/draft'
import { useMiscDraftStore } from '~/stores/miscDraft'
import { useUserBandStore } from '~/stores/userBand'

import pictureBuildHelper from '~/helpers/images/picture_build'

import type { Band } from '~/types/band'

type Props = {
  band: Band
}

const props = defineProps<Props>()

const localePath = useLocalePath()
const route = useRoute()

const { RESET: RESET_DRAFT, RETRIEVE_DRAFT_FROM_LOCAL_STORAGE } =
  useDraftStore()
const { PATCH: PATCH_BAND } = useUserBandStore()
const { SET_BAND_PATCH } = useMiscDraftStore()

const picture = computed(() =>
  pictureBuildHelper({
    kind: 'band',
    slug: props.band.has_profile_picture ? props.band.slug : undefined,
    size: '200_200',
    target: 'profile_picture',
  }),
)

function checkRouteToStayOnPage() {
  const pathsToStayOn = [
    '/band/parameter',
    '/agency/favorites',
    '/band/referral',
    '/band/shop',
    '/lp/about',
    '/lp/cgs',
    '/contact',
    '/partners',
  ]

  return pathsToStayOn.some((path) => route.path.includes(path))
}
const { saveTemporaryDraft } = useDraftRefresh()
async function swapUser() {
  saveTemporaryDraft()
  await PATCH_BAND(props.band.id)

  if (route.path.includes('/band/profile')) {
    await navigateTo(localePath(`/band/profile/${props.band.slug}`))
    await RETRIEVE_DRAFT_FROM_LOCAL_STORAGE()
    return
  }

  if (checkRouteToStayOnPage()) {
    await navigateTo(localePath(route.path), { replace: true })
    await RETRIEVE_DRAFT_FROM_LOCAL_STORAGE()
    return
  }

  // otherwise clean up & go to artist homepage
  SET_BAND_PATCH(props.band.id)
  RESET_DRAFT()
  await RETRIEVE_DRAFT_FROM_LOCAL_STORAGE()
  await navigateTo(localePath('/'))
}
</script>

<template>
  <div class="bandPillWrapper">
    <div class="picture" @click.stop="swapUser">
      <img class="image" :src="picture" :alt="band.name" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.picture {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin-right: 6px;
}

.image {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.91);
  box-shadow: var(--shadow-picture);
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.image:hover {
  filter: brightness(50%);
}

.dot {
  height: 12px;
  width: 12px;
  right: -1px;
  top: -3px;
  border-radius: 50%;
  background-color: var(--color-main);
  border: 2px solid white;
  position: absolute;
}
</style>
