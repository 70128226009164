<script setup lang="ts">
import { useCuratorProfileProgression } from '~/composables/useCuratorProfileProgression'

import type { Band } from '~/types/band'
import type { Influencer } from '~/types/influencer'

type Props = {
  entity: Influencer | Band
  kind: 'band' | 'influencer'
}
type Emits = {
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

useCuratorProfileProgression()

const name = computed(() => {
  const influencer = props.entity as Influencer
  return influencer.entity.charAt(0).toUpperCase() + influencer.entity.slice(1)
})

const link = computed(() => `/${props.kind}/profile/${props.entity.slug}/`)
</script>

<template>
  <div class="tw-grid tw-grid-cols-1 tw-gap-sm">
    <span class="!tw-text-base tw-font-medium">{{ name }}</span>
    <div class="infos">
      <!-- N.B: component hidden for Curators for now -->
      <!-- <span class="tw-text-body-sm tw-text-gray-500">
        {{
          $t('band.RPMenu.completion', { force: profileProgressionPercentage })
        }}
        ·
      </span> -->
      <NuxtLinkLocale
        :to="link"
        data-test-id="profileDropdownViewProfileCTA"
        @click="emit('close')"
      >
        <span class="tw-body-sm !tw-font-medium tw-text-orange-500">
          {{ $t('band.RPMenu.profile') }}
        </span>
      </NuxtLinkLocale>
    </div>
  </div>
</template>
