<script setup lang="ts">
import { UITag } from '@groover-dev/groover-ui'
import { storeToRefs } from 'pinia'

import BandSelect from '~/components/nav/loggedIn/contextMenu/band/bandSelect.vue'
import NavLink from '~/components/nav/NavLink.vue'
import NavLinkCharts from '~/components/nav/NavLinkCharts.vue'

import { useGrowthBook } from '~/composables/useGrowthBook'
import { useSegmentTrackCampaign } from '~/composables/useSegmentTrackCampaign'

import { useUserStore } from '~/stores/user'

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()
const { trackCampaignInitiated } = useSegmentTrackCampaign()
const { isFeatureOnForGroup } = useGrowthBook()

const { grooviz: GROOVIZ, IS_AGENCY } = storeToRefs(useUserStore())

function handleStartACampaignClicked(): void {
  trackCampaignInitiated('header')
  close()
}

function close(): void {
  emit('close')
}
</script>

<template>
  <div class="tw-grid tw-grid-cols-1">
    <NavLink
      to="/draft/"
      class="hoverText"
      @click="handleStartACampaignClicked"
      >{{ $t('layout.navigation11') }}</NavLink
    >
    <NavLink to="/band/dashboard/" class="hoverText" @click="$emit('close')">{{
      $t('layout.navigation04')
    }}</NavLink>
    <!-- Start AB Test 'charts-rollout' -->
    <NavLinkCharts
      v-if="isFeatureOnForGroup('charts-rollout', 'enabled')"
      class="hoverText"
    />
    <!-- End AB Test 'charts-rollout' -->
    <NavLink
      to="/lp/our-services/"
      class="hoverText tw-flex tw-h-[41px] tw-items-center tw-gap-x-2xs"
    >
      {{ $t('layout.navbar.artist.servicesLink') }}
      <UITag
        :text="$t('common.new')"
        color="primary"
        size="sm"
        class="tw-capitalize"
      />
    </NavLink>
    <div v-if="IS_AGENCY" class="tw-grid tw-grid-cols-1 sm:tw-px-5">
      <div class="fakeBorder" />
      <BandSelect @close="$emit('close')" />
      <div class="fakeBorder" />
    </div>
    <span
      v-if="IS_AGENCY"
      class="tw-py-3 !tw-text-base tw-font-medium sm:tw-px-5"
    >
      {{ $t('band.RPMenu.account') }}
    </span>
    <NavLink class="hoverText" to="/agency/favorites/" @click="$emit('close')"
      >{{ $t('layout.menu.menu00') }}
    </NavLink>
    <NavLink
      to="/band/shop/"
      class="hoverText tw-flex tw-items-center tw-justify-start tw-gap-1"
      @click="$emit('close')"
    >
      <span class="feedbackText tw-text-black">{{
        $t('layout.navigation08', GROOVIZ)
      }}</span>
      <i class="icon-grooviz !tw-text-base tw-text-icon-discrete2" />
    </NavLink>
  </div>
</template>

<style lang="scss" scoped>
.fakeBorder {
  @apply tw-my-3 tw-h-px tw-w-full tw-bg-gray-200;
}
</style>
